<template>
    <div class="PostAmountPop">
        <van-overlay :show="isShow">
            <div class="reward_box">
                <div class="rewardTitle">设置价格</div>
<!--                <div class="divisionLine"></div>-->
                <div class="moneyList">
                    <div class="moneyItem" :class="{activeMoney: (activeIndex === index) && !amountVal}" v-for="(item, index) in rewardMoney" :key="index" @click="selectAmount(index)">
                        <div class="goldenBeanNum" v-if="item > 0">{{item}}颗</div>
                        <div class="goldenBeanNum" v-else>免费</div>
                        <svg-icon class="goldenBeans" icon-class="gold2Icon"></svg-icon>
                    </div>
                </div>
                <div class="amontBox">
                    自定义
                    <div class="flex-aic">
                     <van-field class="inputItem" type="number" input-align="center" center border :maxlength="8" v-model="amountVal"></van-field>
                     <svg-icon class="goldenBeans" icon-class="gold2Icon"></svg-icon>
                    </div>
                </div>
                <div class="rewardBtn" @click="setAmount">确定</div>
<!--                <div class="closeBtn" @click="closePop">稍后再说</div>-->
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import { Overlay, Field, Toast } from "vant";
    import { reward } from "@/api/mine";
    import {mapGetters} from "vuex";
    export default {
        components: {
            [Field.name]: Field,
            [Overlay.name]: Overlay,
        },
        data() {
            return {
                rewardMoney: [],
                amountVal: "",
                activeIndex: "",
                isShow: false,
            }
        },
        created() {
            if (Object.prototype.toString.call(this.$store.state.app.appConfig) === "[object String]") {
              this.rewardMoney = JSON.parse(this.$store.state.app.appConfig)?.releaseMoney ?? [10, 20, 30, 40, 50,70,80,90];
            } else {
              this.rewardMoney = this.$store.state.app.appConfig?.releaseMoney ?? [10, 20, 30, 40, 50,70,80,90];
            }
            if(this.rewardMoney.length > 0){
                if(this.rewardMoney[0] != 0){
                    this.rewardMoney.unshift(0);
                }
            }
        },
        methods: {
            showPop() {
                this.isShow = true;
            },
            // 选择金豆
            selectAmount(index) {
                this.activeIndex = index;
                this.amountVal = "";
            },
            // 关闭弹窗
            closePop() {
                this.activeIndex = "";
                this.amountVal = "";
                this.isShow = false;
            },
            // 打赏
            setAmount() {
                // let amount = String(this.activeIndex ? this.rewardMoney[this.activeIndex] : this.amountVal);
                let amount = String(this.amountVal ? this.amountVal : this.rewardMoney[this.activeIndex]);
                this.$emit('setAmount', amount);
                this.isShow = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .van-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
    }

    .PostAmountPop {
        .reward_box {
            width: 300px;
            // height: 375px;
            padding: 25px 18px;
            border-radius: 12px;
            box-sizing: border-box;
            // background: #1b164c;
            //background: url("../../assets/png/gradientBg.png") no-repeat #fff;
            //background: linear-gradient(180deg, #E5FEFF 0%, #FFF 100%);
            background: #FFFFFF;
            background-size: contain;
            .rewardTitle {
                font-size: 18px;
                text-align: center;
            }

            .divisionLine{
                //width: 297px;
                height: 1px;
                margin-top: 12px;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, #000 47.92%, rgba(0, 0, 0, 0.00) 100%);
            }
            .moneyList {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-row-gap: 12px;
                grid-column-gap: 8px;
                margin: 18px 0 18px;
                .moneyItem {
                    width: 61px;
                    height: 60px;
                    background: #F5F5F5;
                    //border: 1px solid #94D6DA;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .goldenBeans {
                        width: 28px;
                        height: 28px;
                    }
                    .goldenBeanNum {
                        font-size: 12px;
                        color: #B09FD9;
                        font-weight: 900;
                    }
                }
                .activeMoney {
                    //border: 1px solid #94D6DA;
                    background: #B09FD9;
                    .goldenBeanNum {
                        color: #FFFFFF;
                    }
                }
            }
            .amontBox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #555555;
                font-size: 15px;
                font-weight: 900;
                .inputItem {
                    padding: 0;
                    margin: 0 12px;
                    background: #e6e6e6;
                    width: 45px;
                    height: 25px;
                    // border: 1px solid #fff;
                    border-radius: 10px;
                    /deep/ .van-field__control {
                      font-size: 12px;
                      color: #000;
                    }
                }
                .goldenBeans {
                 width: 20px;
                 height: 20px;
                }
            }
            .rewardBtn {
                margin: 24px auto 12px;
                width: 256px;
                height: 39px;
                border-radius: 22px;
                background: rgba(255, 255, 255, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                color: rgba(176, 159, 217, 1);
                font-weight: 900;
                box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
            }
            .closeBtn {
                width: 60px;
                height: 22px;
                font-size: 14px;
                color: #999999;
                margin: 0 auto 16px;
            }
        }
    }
</style>
